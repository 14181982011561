// such file name is needed because the link is hardcoded in the mobile app
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
export default function PrivacyPolicy() {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <section id="privacy" className="layout-space">
        <div className="max-w-4xl">
          <div>
            <h1>Privacy Policy</h1>
            <p>Updated October 1, 2019</p>
          </div>
          <hr />
          <div>
            <div>
              <p>
                DeepSine Limited ("us", "we", or "our") operates the
                https://www.voxrec.net website and the VoxRec mobile application
                (hereinafter referred to as the "Service").
              </p>
              <p>
                This document informs you of our policies regarding the
                collection, use and disclosure of personal data when you use our
                Service and the choices you have associated with that data.
              </p>
              <p>
                We use your data to provide and improve the Service. By using
                the Service, you agree to the collection and use of information
                in accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions.
              </p>

              <h3>Definitions</h3>
              <p>Service</p>

              <p>
                Service means the https://www.voxrec.net website and the VoxRec
                mobile application operated by DeepSine Limited.
              </p>
              <p>Personal Data</p>

              <p>
                Personal Data means data about a living individual who can be
                identified from those data (or from those and other information
                either in our possession or likely to come into our possession).
              </p>
              <p>Usage Data</p>
              <p>
                Usage Data is data collected automatically either generated by
                the use of the Service or from the Service infrastructure itself
                (for example, the duration of a page visit).
              </p>
              <p>Service Personal Data</p>
              <p>
                Personal data contained within the audio recordings of sounds
                and conversations captured and uploaded using the Service.
              </p>
              <p>Cookies</p>
              <p>
                Cookies are small files stored on your device (computer or
                mobile device).
              </p>
              <p>Data Controller</p>
              <p>
                Data Controller means the natural or legal person who (either
                alone or jointly or in common with other persons) determines the
                purposes for which and the manner in which any personal
                information are, or are to be, processed. For the purpose of
                this Privacy Policy, we are a Data Controller of your Personal
                Data.
              </p>
              <p>Data Processors (or Service Providers)</p>
              <p>
                Data Processor (or Service Provider) means any natural or legal
                person who processes the data on behalf of the Data Controller.
                We may use the services of various Service Providers in order to
                process your data more effectively.
              </p>
              <p>Data Subject (or User; or Customer)</p>
              <p>
                Data Subject is any living individual who is using our Service
                and is the subject of Personal Data.
              </p>

              <h3>Information Collection and Use</h3>

              <p>
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </p>

              <h3>Types of Data Collected</h3>
              <p>Personal Data</p>

              <p>
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you ("Personal Data"). Personally
                identifiable information may include, but is not limited to:
              </p>

              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>
                <p>First name and last name</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>Email address</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>Address, State, Province, ZIP/Postal code, City</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>Cookies and Usage Data</p>
              </div>

              <p>
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us by following the
                unsubscribe link or instructions provided in any email we send
                or by contacting us.
              </p>
              <p>Service Personal Data &amp; Voice Data</p>

              <p>
                While using our Service, we may provide voice recognition, voice
                processing, text processing and transcription, and we may
                collect personal data as the content within, including voice
                data, sounds, names, phone numbers and other personally
                identifiable details.
              </p>

              <p>
                We do not use this data (Service Personal Data) for marketing
                purposes and we do not sell your data to third parties.
              </p>

              <p>
                Some audio recordings received via the Service, which may
                include personal data, may be reviewed by human reviewers for
                product maintenance and improvement efforts.
              </p>
              <p>
                We store a copy of the recording, the associated transcript for
                the duration until this data is received by the user of the
                Service, after which this data will be deleted within 7 days.
              </p>
              <p>
                We retain information about the user who uploaded the recording
                and related metadata of the recording and transcription, for
                example but not limited to, the length of the audio recording or
                number of words transcribed.
              </p>
              <p>
                We may use raw or partially processed audio segments from
                recordings provided via the Service and associated transcribed
                text from these recordings to be used as Training Data to
                improve the Service and underlying technology (“Improvement”).
              </p>

              <p>Grant and Consent</p>

              <p>
                The Customer hereby grants DeepSine Limited the perpetual,
                irrevocable right to use, de-identify and copy the Customer
                Training Data for the purpose of Improvement.
              </p>

              <p>
                The Customer hereby consents to DeepSine Limited to share the
                Customer Training Data with affiliates, third party vendors and
                contractors of DeepSine Limited, who are bound by non-disclosure
                agreement(s).
              </p>

              <p>
                DeepSine Limited owns all models, improvements of the Service,
                and any associated intellectual property, that result from our
                use of the Customer Data as consented to hereunder.
              </p>

              <p>Usage Data</p>

              <p>
                We may also collect information that your browser sends whenever
                you visit our Service or when you access the Service by or
                through a mobile device or a mobile app ("Usage Data").
              </p>

              <p>
                This Usage Data may include information such as your device's
                Internet Protocol address (e.g. IP address), browser or device
                type, its version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic and performance
                data.
              </p>

              <p>
                When you access the Service with a mobile device, this Usage
                Data may include information such as the type of mobile device
                you use, your mobile device unique ID, the IP address of your
                mobile device, your mobile operating system, the type of mobile
                Internet browser you use, unique device identifiers and other
                diagnostic and performance data.
              </p>

              <p>Location Data</p>

              <p>
                We may use and store information about your location if you give
                us permission to do so ("Location Data"). We use this data to
                provide features of our Service, to improve and customise our
                Service.
              </p>

              <p>
                You can enable or disable location services when you use our
                Service at any time by way of your device settings.
              </p>
              <p>Tracking Cookies Data</p>

              <p>
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
              </p>

              <p>
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyse our
                Service.
              </p>
              <p>
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
              </p>
              <p>Examples of Cookies we use:</p>

              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>
                  Session Cookies. We use Session Cookies to operate our
                  Service.
                </p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>
                  Preference Cookies. We use Preference Cookies to remember your
                  preferences and various settings.
                </p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>
                  Security Cookies. We use Security Cookies for security
                  purposes such as authentication to use the Service.
                </p>
              </div>

              <h3>Use of Data</h3>

              <p>We use the collected data for various purposes:</p>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>To provide and maintain our Service</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>To notify you about changes to our Service</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>
                  To allow you to participate in interactive features of our
                  Service when you choose to do so
                </p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>To provide customer support</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>
                  To gather analysis or valuable information so that we can
                  improve our Service
                </p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>To monitor the usage of our Service</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>To detect, prevent and address technical issues</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>
                  To provide you with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless you have opted not to
                  receive such information
                </p>
              </div>

              <h3>
                Legal Basis for Processing Personal Data under the General Data
                Protection Regulation (GDPR)
              </h3>

              <p>
                If you are from the European Economic Area (EEA), DeepSine
                Limited legal basis for collecting and using the personal
                information described in this Privacy Policy depends on the
                Personal Data we collect and the specific context in which we
                collect it.
              </p>

              <p>DeepSine Limited may process your Personal Data because:</p>

              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>We need to perform a contract with you</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>You have given us permission to do so</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>
                  The processing is in our legitimate interests and it is not
                  overridden by your rights
                </p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>For payment processing purposes</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>To comply with the law</p>
              </div>

              <h3>Retention of Data</h3>

              <p>
                We will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes and enforce our legal agreements and policies.
              </p>

              <p>
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period of time,
                except when this data is used to strengthen the security or to
                improve the functionality of our Service, or we are legally
                obligated to retain this data for longer periods.
              </p>

              <p>
                We may retain the Customer Training Data indefinitely. Customer
                data deletion requests for non-aggregated Customer Training Data
                should be sent via email to privacy@voxrec.net.
              </p>

              <p>
                The rights granted by the Customer related to Customer Training
                Data in this document will survive the termination of the
                Service or termination/expiration of the Terms and Conditions
                document.
              </p>

              <p>
                Your consent to this Privacy Policy followed by your use of the
                Service represents your agreement to that transfer.
              </p>

              <h3>Transfer of Data</h3>

              <p>
                Your information, including Personal Data, may be transferred to
                - and maintained on - computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
              </p>

              <p>
                We may transfer personal data that we collect to our affiliates
                or other companies performing support functions on our behalf
                based in other countries, including countries outside the
                European Economic Area or your country of residence and this
                personal data may be stored and/or processed in such countries.
              </p>

              <p>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </p>

              <p>
                We will take all steps reasonably necessary to ensure that your
                data is treated securely and in accordance with this Privacy
                Policy and no transfer of your Personal Data will take place to
                an organisation or a country unless there are adequate controls
                in place including the security of your data and other personal
                information.
              </p>
              <h3>Disclosure of Data</h3>

              <p>
                Whether your personal data is processed within your country of
                residence or beyond, we will take steps to ensure that your data
                is subject to appropriate safeguards required of us and
                applicable data protection laws.
              </p>
              <p>Business Transaction</p>

              <p>
                If DeepSine Limited is involved in a merger, acquisition or
                asset sale, your Personal Data may be transferred. We will
                provide notice before your Personal Data is transferred and
                becomes subject to a different Privacy Policy.
              </p>
              <p>Disclosure for Law Enforcement</p>

              <p>
                Under certain circumstances, we may be required to disclose your
                Personal Data if required to do so by law or in response to
                valid requests by public authorities (e.g. a court or a
                government agency). Personal data that is transferred outside
                your country of residence may be subject to lawful access by
                courts, law enforcement and other governmental authorities in
                accordance with the laws of the foreign jurisdiction.
              </p>
              <p>Legal Requirements</p>

              <p>
                We may disclose your Personal Data in the good faith belief that
                such action is necessary to:
              </p>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>To comply with a legal obligation</p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>
                  To protect and defend the rights or property of DeepSine
                  Limited
                </p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>
                  To prevent or investigate possible wrongdoing in connection
                  with the Service
                </p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>
                  To protect the personal safety of users of the Service or the
                  public
                </p>
              </div>
              <div className="flex">
                <span className="mr-4 text-xl text-accent">&#10003;</span>

                <p>To protect against legal liability</p>
              </div>

              <h3>Security of Data</h3>

              <p>
                We follow generally accepted standards to protect the personal
                data submitted to us, both during transmission and once it is
                received. Information you provide to us is stored on our secure
                servers. Any payment transactions will be encrypted using
                current PCI-DSS standards.
              </p>

              <h3>
                Our Policy on "Do Not Track" Signals under the California Online
                Protection Act (CalOPPA)
              </h3>

              <p>
                We do not support Do Not Track ("DNT"). Do Not Track is a
                preference you can set in your web browser to inform websites
                that you do not want to be tracked.
              </p>

              <p>
                You can enable or disable Do Not Track by visiting the
                Preferences or Settings page of your web browser.
              </p>

              <h3>
                Your Data Protection Rights under the General Data Protection
                Regulation (GDPR)
              </h3>

              <p>
                If you are a resident of the European Economic Area (EEA), you
                have certain data protection rights. We aim to take reasonable
                steps to allow you to correct, amend, delete or limit the use of
                your Personal Data.
              </p>

              <p>
                If you wish to be informed about what Personal Data we hold
                about you and if you want it to be removed from our systems,
                please contact us.
              </p>

              <p>
                In certain circumstances, you have the following data protection
                rights:
              </p>

              <p>
                <b>
                  The right to access, update or delete the information we have
                  on you.
                </b>{' '}
                Whenever made possible, you can access, update or request
                deletion of your Personal Data directly within your account
                settings section. If you are unable to perform these actions
                yourself, please contact us to assist you.
              </p>

              <p>
                <b>The right of rectification.</b> You have the right to have
                your information rectified if that information is inaccurate or
                incomplete.
              </p>

              <p>
                <b>The right to object.</b> You have the right to object to our
                processing of your Personal Data.
              </p>

              <p>
                <b>The right of restriction.</b> You have the right to request
                that we restrict the processing of your personal information.
              </p>

              <p>
                <b>The right to data portability.</b> You have the right to be
                provided with a copy of the information we have on you in a
                structured, machine-readable and commonly used format.
              </p>

              <p>
                <b>The right to withdraw consent.</b> You also have the right to
                withdraw your consent at any time where we relied on your
                consent to process your personal information.
              </p>

              <p>
                Please note that we may ask you to verify your identity before
                responding to such requests.
              </p>

              <p>
                You have the right to complain to a Data Protection Authority
                about our collection and use of your Personal Data. For more
                information, please contact your local data protection authority
                in the European Economic Area (EEA).
              </p>

              <p>
                You can also write to us with any complaints, at any time by
                contacting us, specifying the nature of your request, at
                privacy@voxrec.net, Attn: Privacy Officer or via post to:
              </p>

              <p>
                Attn: Data Protection Officer
                <br />
                DeepSine Limited
                <br />
                71–75 Shelton Street
                <br />
                London, WC2H 9JQ
                <br />
                United Kingdom
              </p>

              <h3>Service Providers</h3>
              <p>
                We may employ third party companies and individuals to
                facilitate our Service ("Service Providers"), provide the
                Service on our behalf, perform Service-related services or
                assist us in analysing how our Service is used. These third
                parties may have access to your Personal Data and Service
                Personal Data.
              </p>
              <p>
                We may employ Firebase and/or Google Analytics - an analytics
                service offered by Google that tracks and reports website
                traffic and mobile application usage. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its
                own advertising network.
              </p>
              <p>
                Your data may be stored and processed using Google Cloud
                Platform - a suite of cloud computing services for storage and
                processing of data provided by Google Inc.
              </p>
              <p>
                For more information on the privacy practices of Google, how and
                what type of information the analytics platform collect, or to
                opt-out of certain analytics and advertising features through
                your mobile device settings, such as your device advertising
                settings or by following the instructions provided by Google in
                their Privacy Policy: https://policies.google.com/privacy
              </p>
              <p>
                We also encourage you to review the Google's policy for
                safeguarding your data:
                https://support.google.com/analytics/answer/6004245
              </p>
              <p>Remarketing</p>
              <p>
                We use remarketing services to advertise on third party websites
                to you after you visited our Service. We and our third-party
                vendors use cookies to inform, optimise and serve ads based on
                your past visits to our Service.
              </p>
              <p>
                <b>Google Ads (AdWords)</b>
                <br />
                Google Ads (AdWords) remarketing service is provided by Google
                Inc. You can opt-out of Google Analytics for Display Advertising
                and customise the Google Display Network ads by visiting the
                Google Ads Settings page: http://www.google.com/settings/ads
                <br />
                For more information on the privacy practices of Google, please
                visit the Google Privacy Terms web page:
                https://policies.google.com/privacy
              </p>
              <p>
                <b>Bing Ads Remarketing</b>
                <br />
                Bing Ads remarketing service is provided by Microsoft Inc. You
                can opt-out of Bing Ads interest-based ads by following their
                instructions:
                https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                <br />
                You can learn more about the privacy practices and policies of
                Microsoft by visiting their Privacy Policy page:
                https://privacy.microsoft.com/en-us/PrivacyStatement
              </p>
              <p>
                <b>Facebook</b>
                <br />
                Facebook remarketing service is provided by Facebook Inc. You
                can learn more about interest-based advertising from Facebook by
                visiting this page:
                https://www.facebook.com/help/164968693837950
                <br />
                To opt-out from Facebook's interest-based ads, follow these
                instructions from Facebook:
                https://www.facebook.com/help/568137493302217
                <br />
                Facebook adheres to the Self-Regulatory Principles for Online
                Behavioural Advertising established by the Digital Advertising
                Alliance. You can also opt-out from Facebook and other
                participating companies through the Digital Advertising Alliance
                in the USA http://www.aboutads.info/choices/, the Digital
                Advertising Alliance of Canada in Canada
                http://youradchoices.ca/ or the European Interactive Digital
                Advertising Alliance in Europe http://www.youronlinechoices.eu/,
                or opt-out using your mobile device settings.
                <br />
                For more information on the privacy practices of Facebook,
                please visit Facebook's Data Policy:
                https://www.facebook.com/privacy/explanation
              </p>
              <p>Payments</p>
              <p>
                We may provide paid products and/or services within the Service.
                In that case, we use third-party services for payment processing
                (e.g. payment processors).
              </p>
              <p>
                We will not store or collect your payment card details. That
                information is provided directly to our third-party payment
                processors whose use of your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa,
                MasterCard, American Express and Discover. PCI-DSS requirements
                help ensure the secure handling of payment information.
              </p>
              <p>The payment processors we work with are:</p>
              <p>
                <b>Apple Store In-App Payments</b>
                <br />
                Their Privacy Policy can be viewed at
                https://www.apple.com/legal/privacy/en-ww/
              </p>
              <p>
                <b>Google Play In-App Payments</b>
                <br />
                Their Privacy Policy can be viewed at
                https://www.google.com/policies/privacy/
              </p>
              <p>
                <b>Stripe</b>
                <br />
                Their Privacy Policy can be viewed at
                https://stripe.com/us/privacy
              </p>
              <p>
                <b>PayPal / Braintree</b>
                <br />
                Their Privacy Policy can be viewed at
                https://www.paypal.com/webapps/mpp/ua/privacy-full
              </p>
              <h3>Links to Other Sites</h3>
              <p>
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
              <h3>Children's Privacy</h3>
              <p>
                Our Service does not address anyone under the age of 18
                ("Children").
              </p>
              <p>
                We do not knowingly collect personally identifiable information
                from anyone under the age of 18. If you are a parent or guardian
                and you are aware that your Child has provided us with Personal
                Data, please contact us. If we become aware that we have
                collected Personal Data from children without verification of
                parental consent, we take steps to remove that information from
                our servers.
              </p>
              <p>
                No child under the age of 18 should submit personal data via the
                Service. If you become aware that a child under the age of 18
                has submitted personal data to us, contact us at
                privacy@voxrec.net and we will promptly delete the data.
              </p>
              <h3>Changes to This Privacy Policy</h3>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p>
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update the
                "effective date" at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
              <h3>Contact Us</h3>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us:
                <br />
                By email: privacy@voxrec.net
                <br />
                By visiting this page on our website: https://www.voxrec.net
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
